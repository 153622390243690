export const pink = {
  pink1: 'hsl(318, 25.0%, 9.6%)',
  pink2: 'hsl(319, 32.2%, 11.6%)',
  pink3: 'hsl(319, 41.0%, 16.0%)',
  pink4: 'hsl(320, 45.4%, 18.7%)',
  pink5: 'hsl(320, 49.0%, 21.1%)',
  pink6: 'hsl(321, 53.6%, 24.4%)',
  pink7: 'hsl(321, 61.1%, 29.7%)',
  pink8: 'hsl(322, 74.9%, 37.5%)',
  pink9: 'hsl(322, 65.0%, 54.5%)',
  pink10: 'hsl(323, 72.8%, 59.2%)',
  pink11: 'hsl(325, 90.0%, 66.4%)',
  pink12: 'hsl(322, 90.0%, 95.8%)',
}
