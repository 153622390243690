export const blue = {
  blue1: 'hsl(212, 35.0%, 9.2%)',
  blue2: 'hsl(216, 50.0%, 11.8%)',
  blue3: 'hsl(214, 59.4%, 15.3%)',
  blue4: 'hsl(214, 65.8%, 17.9%)',
  blue5: 'hsl(213, 71.2%, 20.2%)',
  blue6: 'hsl(212, 77.4%, 23.1%)',
  blue7: 'hsl(211, 85.1%, 27.4%)',
  blue8: 'hsl(211, 89.7%, 34.1%)',
  blue9: 'hsl(206, 100%, 50.0%)',
  blue10: 'hsl(209, 100%, 60.6%)',
  blue11: 'hsl(210, 100%, 66.1%)',
  blue12: 'hsl(206, 98.0%, 95.8%)',
}
