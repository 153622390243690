export const pink = {
  pink1: 'hsl(322, 100%, 99.4%)',
  pink2: 'hsl(323, 100%, 98.4%)',
  pink3: 'hsl(323, 86.3%, 96.5%)',
  pink4: 'hsl(323, 78.7%, 94.2%)',
  pink5: 'hsl(323, 72.2%, 91.1%)',
  pink6: 'hsl(323, 66.3%, 86.6%)',
  pink7: 'hsl(323, 62.0%, 80.1%)',
  pink8: 'hsl(323, 60.3%, 72.4%)',
  pink9: 'hsl(322, 65.0%, 54.5%)',
  pink10: 'hsl(322, 63.9%, 50.7%)',
  pink11: 'hsl(322, 75.0%, 46.0%)',
  pink12: 'hsl(320, 70.0%, 13.5%)',
}
