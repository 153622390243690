export const orange = {
  orange1: 'hsl(30, 70.0%, 7.2%)',
  orange2: 'hsl(28, 100%, 8.4%)',
  orange3: 'hsl(26, 91.1%, 11.6%)',
  orange4: 'hsl(25, 88.3%, 14.1%)',
  orange5: 'hsl(24, 87.6%, 16.6%)',
  orange6: 'hsl(24, 88.6%, 19.8%)',
  orange7: 'hsl(24, 92.4%, 24.0%)',
  orange8: 'hsl(25, 100%, 29.0%)',
  orange9: 'hsl(24, 94.0%, 50.0%)',
  orange10: 'hsl(24, 100%, 58.5%)',
  orange11: 'hsl(24, 100%, 62.2%)',
  orange12: 'hsl(24, 97.0%, 93.2%)',
}
